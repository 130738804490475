import { FC, useEffect, useState } from "react";
import theme from "../../theme";
import { ResponsiveLine } from "@nivo/line";
import {
  getMilestonesOverTime,
  getPersonalExpensesOverTime,
  getTotalEarningsOverTime,
  getTotalExpensesOverTime,
  getTotalLoanExpensesOverTime,
} from "../../simulations/utils";
import styled from "styled-components";
import { LoanPlan, SingleSimulationResults } from "../../types";
import GraphTooltip from "./GraphTooltip";
import { kFormatter } from "../../utils";

interface StandardPlanProps {
  results: SingleSimulationResults;
  loanPlan: LoanPlan;
}

const Style = styled.div``;

const SimulationLineChart: FC<StandardPlanProps> = ({ results, loanPlan }) => {
  const mobileWidth = theme.breakpoints.xsmall;
  const windowWidth = window.innerWidth;
  const [mobile, setMobile] = useState(windowWidth < mobileWidth);

  useEffect(() => {
    setMobile(windowWidth < mobileWidth);
  }, [mobile, mobileWidth, windowWidth]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setMobile(window.innerWidth < mobileWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setMobile(window.innerWidth < mobileWidth);
      });
    };
  }, [mobileWidth]);

  const personalExpensesOverTime = getPersonalExpensesOverTime(
    results,
    loanPlan,
  );

  const totalEarningsOverTime = getTotalEarningsOverTime(results);

  const totalExpensesOverTime = getTotalExpensesOverTime(
    results,
    loanPlan,
    personalExpensesOverTime,
  );

  const totalLoanExpensesOverTime = getTotalLoanExpensesOverTime(
    results,
    loanPlan,
  );

  const milestonesOverTime = getMilestonesOverTime(results);

  return (
    <Style>
      <div className="graph">
        <ResponsiveLine
          data={[
            {
              id: "Student Loan Payments",
              label: "Annual Student Loan Payment",
              color: theme.colors.graphColors.lightOrange,
              data: totalLoanExpensesOverTime,
            },
            {
              id: "Total Expenses",
              label: "Student Loan Expenses",
              color: theme.colors.graphColors.redOrange,
              data: totalExpensesOverTime,
            },
            {
              id: "Annual Income",
              color: theme.colors.graphColors.turquoise,
              label: "Income",
              data: totalEarningsOverTime,
            },
          ]}
          margin={
            window.innerWidth < mobileWidth
              ? { top: 70, right: 20, bottom: 50, left: 50 }
              : { top: 60, right: 20, bottom: 50, left: 70 }
          }
          colors={[
            theme.colors.graphColors.lightOrange,
            theme.colors.graphColors.redOrange,
            theme.colors.graphColors.turquoise,
          ]}
          isInteractive={true}
          useMesh={true}
          enableCrosshair={true}
          enableArea={false}
          pointSize={window.innerWidth < mobileWidth ? 3 : 10}
          pointColor={{ from: "color" }}
          pointBorderWidth={window.innerWidth < mobileWidth ? 1 : 2}
          pointBorderColor={{ from: "serieColor" }}
          axisTop={null}
          axisRight={null}
          curve="monotoneX"
          axisLeft={
            window.innerWidth < mobileWidth
              ? {
                  tickSize: 0,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: 3,
                  format: (v) => `$${kFormatter(v)}`,
                }
              : {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: 3,
                  format: (v) => `$${kFormatter(v)}`,
                }
          }
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            renderTick: ({ opacity, textAnchor, value, x, y }) => {
              return (
                <g transform={`translate(${x},${y})`} style={{ opacity }}>
                  <text
                    textAnchor={textAnchor}
                    transform={`translate(${0},${mobile ? 15 : 20})`}
                    alignmentBaseline={"baseline"}
                    fontSize={mobile ? "10px" : "18px"}
                  >
                    {mobile ? value + 1 : `Y${value + 1}`}
                  </text>
                  {!mobile && (
                    <text
                      textAnchor={textAnchor}
                      transform={`translate(${0},${40})`}
                      alignmentBaseline={"baseline"}
                      fontSize="10px"
                    >
                      {22 + value} y.o.
                    </text>
                  )}
                </g>
              );
            },
            legend: mobile ? "Years after graduation" : undefined,
            legendOffset: 36,
            legendPosition: "middle",
          }}
          xFormat={" >-,"}
          theme={{
            text: {
              fontSize: 16,
              fontFamily: "Karla",
            },
            legends: { text: { fontSize: 14 } },
            axis: {
              legend: {
                text: {
                  fontSize: 14,
                },
              },
              domain: {
                line: {
                  stroke: "#777777",
                  strokeWidth: 1,
                },
              },
            },
          }}
          legends={[
            {
              anchor: "top-left",
              direction: mobile ? "column" : "row",
              justify: false,
              // translateX: 180,
              translateY: mobile ? -65 : -40,
              itemWidth: 130,
              itemHeight: 12,
              symbolSize: 16,
              itemDirection: "left-to-right",
              toggleSerie: true,
              itemsSpacing: 10,
            },
          ]}
          enableSlices="x"
          enableGridX={false}
          enableGridY={false}
          sliceTooltip={(pointOBJ) => {
            const index = Number(pointOBJ.slice.points[0].data.x);
            return (
              <GraphTooltip
                index={index}
                totalLoanExpensesOverTime={totalLoanExpensesOverTime}
                totalExpensesOverTime={totalExpensesOverTime}
                totalEarningsOverTime={totalEarningsOverTime}
                personalExpensesOverTime={personalExpensesOverTime}
                milestonesOverTime={milestonesOverTime}
              />
            );
          }}
        />
      </div>
    </Style>
  );
};

export default SimulationLineChart;
