import { CardActionArea } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { FC } from "react";
import styled from "styled-components";
import theme from "../theme";

interface HorizontalCardProps {
  title: string;
  date: string;
  sourceName?: string;
  description: string | JSX.Element;
  link: string;
  buttonText: string;
  featured?: boolean;
}

const Style = styled.div`
  .subline {
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    color: ${theme.colors.neutrals.darkGray};
    display: flex;
    align-items: center;
    gap: 16px;
  }

  h1 {
    font-size: 1.4rem;
  }

  .featured-tag {
    background: ${theme.colors.primary};
    color: ${theme.colors.neutrals.white};
    padding: 8px 12px;
    font-weight: 700;
    border-radius: 1px;
    width: fit-content;
    border: 2px solid;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .subline {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

const HorizontalCard: FC<HorizontalCardProps> = ({
  title,
  description,
  sourceName,
  date,
  buttonText,
  link,
  featured = false,
}) => {
  return (
    <Style>
      <CardActionArea href={link}>
        <Card
          sx={{
            minWidth: 275,
            borderRadius: "4px",
          }}
          variant="outlined"
        >
          <CardContent>
            <p className="subline">
              {featured && <span className="featured-tag">FEATURED</span>}
              <span>
                {date} {sourceName && " • "} {sourceName}
              </span>
            </p>
            <h1>{title}</h1>
            <p>{description}</p>
          </CardContent>
          <CardActions>
            <Button size="small" sx={{ color: theme.colors.primary }}>
              <b>{buttonText}</b>
            </Button>
          </CardActions>
        </Card>
      </CardActionArea>
    </Style>
  );
};

export default HorizontalCard;
