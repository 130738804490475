import { FC, useEffect, useState } from "react";
import { BarDatum, LegendLabelDatum, ResponsiveBar } from "@nivo/bar";
import theme from "../../theme";
import { GraphProps } from "../../types";

let isMobile: boolean;
const HorizontalSimpleBarGraph: FC<GraphProps> = ({
  data,
  colors = [theme.colors.primary],
  layout = "vertical",
  legendLabelMap,
  xAxisKey,
  yAxisKeys,
  legendWidth,
  graphAreaWidth = window.innerWidth,
  showLegend = false,
  isInteractive = false,
}) => {
  const mobileBreakpoint = theme.breakpoints.large;
  const [mobile, setMobile] = useState(graphAreaWidth < mobileBreakpoint);
  useEffect(() => {
    setMobile(graphAreaWidth < mobileBreakpoint);
  }, [mobile, mobileBreakpoint, graphAreaWidth]);
  window.addEventListener("resize", () => {
    setMobile(window.innerWidth < mobileBreakpoint);
  });
  isMobile = mobile;
  return (
    <ResponsiveBar
      valueFormat=" ^-$,"
      data={data}
      legendLabel={(x: LegendLabelDatum<BarDatum>) => {
        return `${legendLabelMap ? legendLabelMap[x.id] : x.id}`;
      }}
      tooltipLabel={(input) => {
        return legendLabelMap ? legendLabelMap[input.id] : input.id.toString();
      }}
      keys={yAxisKeys}
      enableLabel={false}
      layout={layout}
      indexBy={xAxisKey}
      padding={isMobile ? 0.8 : 0.7}
      enableGridY={false}
      enableGridX={false}
      valueScale={{ type: "linear" }}
      colors={colors}
      animate={true}
      axisTop={null}
      axisRight={null}
      axisLeft={null}
      isInteractive={isInteractive}
      theme={{
        text: {
          fontSize: 12,
          fontFamily: "Karla",
        },
        legends: { text: { fontSize: 12 } },
        axis: {
          ticks: {
            text: {
              fontSize: 12,
              fill: "#000000",
              fontWeight: "bolder",
            },
          },
        },
      }}
      axisBottom={null}
      legends={
        showLegend
          ? [
              {
                dataFrom: "keys",
                anchor: "top-left",
                direction: "column",
                justify: false,
                translateY: 0,
                itemWidth: legendWidth || 250,
                itemHeight: 20,
                itemsSpacing: 0,
                symbolSize: 16,
                itemDirection: "left-to-right",
                toggleSerie: true,
              },
            ]
          : []
      }
    />
  );
};

export default HorizontalSimpleBarGraph;
