import styled from "styled-components";
import Button from "../components/Button";
import Nav from "../components/Nav";
import theme from "../theme";
import RealLogo from "../assets/icons/finiverse-logo.svg";
import Wallet from "../assets/images/wallet.svg";
import { Link, useNavigate } from "react-router-dom";
import rightArrow from "../assets/right-arrow.svg";
import Footer from "../components/Footer";
import Banner from "../components/Banner";

const Style = styled.div`
  height: 100vh;

  .logo-image {
    width: 30%;
  }

  .content {
    padding: 20px 100px;
    background: ${theme.colors.veryLightOrange};
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.1);

    .section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 30px;
      padding-bottom: 30px;
      gap: 30px;
    }

    .section-text {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        font-size: 3rem;
        margin-bottom: 30px !important;
      }
    }

    .logo-image {
      width: 30%;
    }

    .stock-image {
      width: 40%;
    }

    .description {
      font-size: 22px;
      margin-bottom: 36px;
    }

    ul.description {
      font-size: 22px;
      line-height: 110%;
      margin-bottom: 36px;

      li {
        margin-bottom: 16px;
      }
    }

    .button-container {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
  @media (max-width: ${theme.breakpoints.small}px) {
    .content {
      padding-left: 20px;
      padding-right: 20px;
    }
    .content > .section {
      width: 100%;
      flex-direction: column;
      gap: 50px;
      align-items: flex-start;
      padding: 0px 20px;
    }
    .content > .section > .section-text {
      width: 80%;
    }

    .logo-image {
      width: 60% !important;
    }

    .stock-image {
      display: none;
    }

    .content > .section > .section-text > .title {
      font-size: 36px;
      width: 100%;
    }
    .content > .section > .section-text > .description {
      font-size: 20px;
      width: 100%;
      padding-left: 10px;
    }
    .content > div:nth-child(2) > div > ul {
      margin-bottom: 24px;
    }
    .content > div:nth-child(2) {
      padding-bottom: 70px;
    }

    .button-text {
      font-size: 20px !important;
    }
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .content > .section {
      width: 100%;
      flex-direction: column;
      gap: 50px;
      align-items: flex-start;
    }
    .title {
      font-size: 2.5rem !important;
    }

    .logo-image {
      width: 40% !important;
    }

    .stock-image {
      display: none;
    }
  }

  @media (max-width: ${theme.breakpoints.xlarge}px) {
    .title {
      font-size: 2.5rem !important;
    }

    .logo-image {
      width: 30%;
    }

    .stock-image {
      width: 50%;
    }
  }
`;

export const Home = () => {
  const navigate = useNavigate();
  return (
    <Style>
      <Nav />
      <Banner
        content={
          <>
            We're in the news!{" "}
            <Link to="/news" style={{ color: "white", fontWeight: "700" }}>
              Check out our latest feature on the Philadelphia Inquirer &rarr;
            </Link>
          </>
        }
      />
      <div className="content">
        <div className="section">
          <div className="section-text">
            <h1 className="title">
              College is a big decision. We’re here to help.
            </h1>
            <p className="description">
              Going to college affects your financial future. Finiverse can help
              you make informed decisions about your education and finances.
            </p>
            <div className="button-container">
              <Button
                handleOnClick={() => navigate("/college-costs")}
                buttonText={"Get Started"}
                buttonRightIcon={rightArrow}
              />
            </div>
          </div>
          <img className="logo-image" src={RealLogo} alt="Finiverse logo" />
        </div>
        <div className="section">
          <img className="stock-image" src={Wallet} alt="wallet" />
          <div className="section-text">
            <h1 className="title">A tool for your college financial needs</h1>
            <ul className="description">
              <li>
                Explore a “multi-verse” simulation to see how taking longer to
                graduate could affect your financial future
              </li>
              <li>
                Learn how income-driven repayment plans could save you a lot of
                money after college
              </li>
              <li>Compare the costs of 4 different colleges at once.</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </Style>
  );
};

export default Home;
