import styled from "styled-components";
import theme from "../theme";
import TextCallout from "../components/TextCallout";
const Style = styled.div``;

const StandardPlanSimulationTab = () => {
  return (
    <Style>
      <TextCallout
        text={
          <>
            <p>
              If you expect to have a low income after graduation, you may want
              to consider an <b>income-driven repayment plan(IDR)</b> instead.
            </p>
            <p>
              In an income-driven repayment plan, the amount you pay each month
              is based on how much money you make. Learn more about IDR in this{" "}
              <a
                href="https://studentloanapp.freshdesk.com/support/solutions/articles/151000096777-what-is-the-idr-plan-"
                style={{
                  textDecorationLine: "underline",
                  color: theme.colors.neutrals.black,
                }}
              >
                this help desk article.
              </a>
            </p>
          </>
        }
      />
    </Style>
  );
};

export default StandardPlanSimulationTab;
