import styled from "styled-components";
import theme from "../theme";
import TextCallout from "../components/TextCallout";

const Style = styled.div``;

const StandardPlanSimulationTab = () => {
  return (
    <Style>
      <TextCallout
        text={
          <>
            <p>
              Once you finish school, you'll have to start paying back your
              student loans. There are <b>different loan repayment plans</b>,
              which are methods or schedules for paying back loans.
            </p>

            <p>
              In a <b>standard repayment plan</b>, you pay a fixed amount every
              month for a set period of time (usually 10 years). For more
              information, you can read{" "}
              <a
                href="https://studentaid.gov/manage-loans/repayment/plans/standard"
                style={{
                  textDecorationLine: "underline",
                  color: theme.colors.neutrals.black,
                }}
              >
                this Federal Student Aid article.
              </a>
            </p>

            <p>
              In the graph below, you can see how much money you would need to
              pay each year during the repayment period of 10 years. After 10
              years, you will notice that your expenses become $0 because you
              will have paid off your student loans.
            </p>
          </>
        }
      />
    </Style>
  );
};

export default StandardPlanSimulationTab;
