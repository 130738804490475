import theme from "../../theme";
import { FC } from "react";
import { LoanPlan, SingleSimulationResults } from "../../types";
import {
  createAnnualLineComponent,
  getAnnualExpensesPoints,
  getAnnualIncomePoints,
  getAnnualStudentLoanPoints,
  getPersonalExpensesOverTime,
  getTotalEarningsOverTime,
} from "../../simulations/utils";
import SimulationBarChart from "./SimulationBarGraph";

interface StandardPlanProps {
  results: SingleSimulationResults;
  loanPlan: LoanPlan;
}

const SimulationCombinedGraph: FC<StandardPlanProps> = ({
  results,
  loanPlan,
}) => {
  const personalExpensesOverTime = getPersonalExpensesOverTime(
    results,
    loanPlan,
  );

  const totalEarningsOverTime = getTotalEarningsOverTime(results);

  const annualIncomePoints = getAnnualIncomePoints(
    results,
    loanPlan,
    totalEarningsOverTime,
    personalExpensesOverTime,
  );

  const annualExpensesPoints = getAnnualExpensesPoints(
    results,
    loanPlan,
    personalExpensesOverTime,
  );

  const annualStudentLoanPoints = getAnnualStudentLoanPoints(results, loanPlan);

  // Drawing out line components for the annual income, expenses, and student loan payments
  const AnnualIncomeLine = createAnnualLineComponent(
    "totalIncome",
    annualIncomePoints,
    theme.colors.graphColors.turquoise,
  );

  const AnnualExpensesLine = createAnnualLineComponent(
    "taxes",
    annualExpensesPoints,
    theme.colors.graphColors.redOrange,
  );

  const AnnualStudentLoanLine = createAnnualLineComponent(
    "studentLoanPayments",
    annualStudentLoanPoints,
    theme.colors.graphColors.lightOrange,
  );

  return (
    <SimulationBarChart
      results={results}
      loanPlan={loanPlan}
      layers={[
        "grid",
        "axes",
        "bars",
        "markers",
        "legends",
        AnnualIncomeLine,
        AnnualExpensesLine,
        AnnualStudentLoanLine,
      ]}
      showTotalExpensesLegend
      disableLegendClick
    />
  );
};

export default SimulationCombinedGraph;
