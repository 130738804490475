import theme from "./theme";
import { Page, SimulationMetrics } from "./types";

export type PageNames =
  | "COLLEGE_COSTS"
  | "FINANCING"
  | "RISK_SIMULATION"
  | "MISSION"
  | "STORY"
  | "TEAM"
  | "NEWS"
  | "CONTACT";

export const pages: Page[] = [
  {
    name: "College Costs",
    link: "/college-costs",
    id: "COLLEGE_COSTS",
  },
  {
    name: "Financing",
    link: "/financing-options",
    id: "FINANCING",
  },
  {
    name: "Risk Simulation",
    link: "/risk-simulation",
    id: "RISK_SIMULATION",
  },
];

export const abouts: Page[] = [
  { name: "Our Mission", link: "/our-mission", id: "MISSION" },
  { name: "Our Story", link: "/our-story", id: "STORY" },
  { name: "Our Team", link: "/our-team", id: "TEAM" },
  { name: "News", link: "/news", id: "NEWS" },
  { name: "Contact Us", link: "/contact-us", id: "CONTACT" },
];

export const Pages = {
  "Finiverse Tool": pages,
  "About Us": abouts,
};

export const costCategoryLegendMap: { [key: string]: string } = {
  tuition: "Tuition",
  fees: "Fees",
  booksAndSupplies: "Books & Supplies",
  roomAndBoard: "Room & Board",
  personalExpenses: "Personal Expenses",
};

export const financialSourceLegendMap: { [key: string]: string } = {
  federalAid: "Federal Aid",
  stateAndLocalAid: "Other Govt. Aid",
  institutionalAid: "Institutional Aid",
  annualNetPrice: "Net Price",
  familyContribution: "Family Contribution",
  other: "Other",
  loanAmount: "Loan Amount",
};

export const studentLoanTypeLegendMap: { [key: string]: string } = {
  totalPrivate: "Private loan balance",
  totalSubsidized: "Federal subsidized loan balance",
  totalUnsubsidized: "Federal unsubsidized loan balance",
};

export const loanRepaymentMap: { [key: string]: string } = {
  principal: "Principal",
  interest: "Interest",
};

export const currencyRegex = /^\d+(,\d{3})*(\.\d*)?$|^$/;
export const integerRegex = /^\d+$/;
export const integerGreaterThan1 = /^[2-9]|[1-9]\d+$/;

export const invalidInputMessage = "*Please enter a proper number";

export const requiredInputMessage = "*This field is required";

// TODO: add taxes to average take home pay
export const metricMap = {
  "Average annual take-home pay": {
    key: "avgAnnualTakeHomePay",
    barColor: [theme.colors.primary],
    textColor: "blue",
    showDollarSign: true,
    showYears: false,
    description:
      "How much of your earnings you get to keep each year after taxes and student loan payments",
    action: "would earn an average annual take-home pay of",
  },
  "Average annual income": {
    key: "avgAnnualIncome",
    barColor: [theme.colors.graphColors.lavender],
    textColor: "purple",
    showDollarSign: true,
    showYears: false,
    description:
      "How much you would earn each year (before student loan payments and taxes)",
    action: "would earn an average annual income of",
  },
} as SimulationMetrics;

// The order of `loanTypeGraphKeys` determines the order in which we progress through the loan explanations, the order in which the bar graph grows, and which numbers are displayed
// In the future, we want to change this to be more dynamic, but for now be careful when changing the order of this list
export const loanTypeGraphKeys: string[] = [
  "totalLoanBalanceFromLastYear",
  "federalSubsidizedPrincipalAmt",
  "federalUnsubsidizedPrincipalAmt",
  "privatePrincipalAmt",
  "federalSubsidizedInterestAmt",
  "federalUnsubsidizedInterestAmt",
  "privateInterestAmt",
];

export const loanTypeGraphColors: string[] = [
  theme.colors.graphColors.eggplantPurple,
  theme.colors.lightGreen,
  theme.colors.graphColors.indigo,
  theme.colors.graphColors.eggplantPurple,
  theme.colors.lightGreen,
  theme.colors.graphColors.indigo,
];

export const legendColors = {
  studentLoans: theme.colors.graphColors.lightOrange,
  taxes: theme.colors.graphColors.darkPurple,
  other: theme.colors.graphColors.indigo,
  totalIncome: theme.colors.graphColors.turquoise,
  totalExpenses: theme.colors.graphColors.redOrange,
};

export const searchDebounceTime = 250; // milliseconds
