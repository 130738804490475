import { BarDatum } from "@nivo/bar";
import { PageNames } from "./constants";

export interface GraphProps {
  data: any[];
  colors?: string[];
  legendLabelMap?: { [key: string]: string };
  layout?: "horizontal" | "vertical" | undefined;
  xAxisKey: string;
  yAxisKeys: string[];
  xAxisLabel: string;
  yAxisLabel?: string;
  legendWidth?: number;
  graphAreaWidth?: number;
  showYAxis?: boolean;
  showLegend?: boolean;
  graphHeight?: number;
  fontSize?: number;
  showDollarSign?: boolean;
  isInteractive?: boolean;
  enableLabel?: boolean;
}

export interface CheckboxOption {
  label: string;
  color: string;
  toolTipText?: string;
}

export interface Page {
  name: string;
  link: string;
  id: PageNames;
}

export interface NavigationState {
  showSummaryButton: boolean;
  activePages: PageNames[];
}

// College Costs types
export interface CollegeCostsState {
  dropdownOptions: CollegeCostsDropdownOptions;
  selectedOptions: SelectedCollegeCostsInputs;
  showStickerPriceResults: boolean;
  showNetPriceResults: boolean;
  results: CollegeCostsResults[];
}

export interface SelectedCollegeCostsInputs {
  college: College | undefined;
  programOfStudy: string;
  major: string;
  stateOfResidence: string;
}

export interface College {
  collegeId: string;
  name: string;
}

export interface CollegeCostsDropdownOptions {
  colleges: College[];
  majors: string[];
  programsOfStudy: string[];
  householdIncomeBrackets: string[];
}

export interface CollegeCostsResults {
  collegeId: string;
  schoolName: string;
  totalAnnualCost: number;
  tuition: number;
  fees: number;
  supplies: number;
  roomAndBoard: number;
  otherExpenses: number;
  averageTimeToComplete: number;
  totalCollegeCost: number;
  estimatedFinancialAid: number | null;
  expectedFamilyContribution: number;
  otherFinancialAid: number;
  netAnnualCost: number;
  netTotalCollegeCost: number;
  inState: boolean;
  publicSchool: boolean;
}

export type PopupType = keyof typeof PopupTypes;

export const PopupTypes = {
  ADD_SCHOOL: "ADD_SCHOOL" as const,
  EDIT_SAI: "EDIT_SAI" as const,
  ParentPlus_Eligibility: "ParentPlus_Eligibility" as const,
};

export interface Major {
  majorName: string;
  majorCode: string;
}

export interface Job {
  socCode: string;
  jobTitle: string;
  avgStartingSalary: number;
}

export interface JobDropdownOptions {
  majors: Major[];
  jobs: Job[];
}

export interface JobEarningsResults {
  jobOptions: JobOption[];
}

export interface JobOption {
  jobTitle: string;
  jobDescription: string;
  avgStartingSalary: number;
  salaryOverTime: BarDatum[];
}

// Financing Options types
export interface FinancingOptionsState {
  showResults: boolean;
  results: FinancingOptionsResults[];
  financingOptions: FinancingOptions[];
  collegeFinancialSources: CollegeFinancialSources[];
  jobDropdownOptions: JobDropdownOptions;
  selectedJob: Job | undefined;
  selectedSchool: College;
  showSimulationResults: boolean;
  loanAccumulationStepIndex: number;
  currentYear: number;
  totalNumberOfYears: number;
  loanBalanceSoFar: LoanBalanceSoFar;
  currentCollegeFinancialSources: CollegeFinancialSources; // financial source information for selected college
  currentFinancingOptionsResult: FinancingOptionsResults; // financing options information for selected college
  selectedFinancialOptionsResult: FinancingOptions;
}

export interface LoanBalanceSoFar {
  total: number;
  federalSubsidized: number;
  federalUnsubsidized: number;
  private: number;
}

// We plan to eventually remove FinancingOptions because it is essentially a subset of CollegeFinancialSources
export interface FinancingOptions {
  collegeId: string;
  studentAidIndexAmount: string;
  pellGrantAmount: string;
  parentPlusLoanAmount: string;
  familyContributionAmount: string;
  otherAmount: string;
  studentLoanAmount: string;
  userHasUpdatedThis: boolean;
}

export interface CollegeFinancialSources {
  collegeId: string;
  schoolName: string;
  federalAid: number;
  stateAndLocalAid: number;
  institutionalAid: number;
  pellGrantAmount: number;
  otherGovernmentalAid: number;
  parentPlusLoanAmount: number;
  familyContribution: number;
  other: number;
  loanAmount: number;
  annualNetPrice: number;
  totalAnnualCost: number;
  totalCollegeCost: number;
  totalLoanAmount: number;
  inState: boolean;
  publicSchool: boolean;
  coefficientAvailable: boolean;
}

export interface FinancingOptionsResults {
  collegeId: string;
  totalLoanAmount: number;
  totalSubsidized: number;
  totalUnsubsidized: number;
  totalPrivate: number;
  financingInfoStandard: any; // TODO: remove this
  annualLoanAccumulationData: YearlyLoanAmount[];
}

export interface YearlyLoanAmount {
  year: number;
  netPriceThisYear: number;
  totalLoanBalanceFromLastYear: number; // loan balance from last year
  federalSubsidizedLoanBalanceFromLastYear: number;
  federalUnsubsidizedLoanBalanceFromLastYear: number;
  privateLoanBalanceFromLastYear: number;
  totalLoanPrincipalThisYear: number; // sum of federal loan and private loan principal (without interest)
  federalSubsidizedPrincipalAmt: number;
  federalSubsidizedLoanMax: number;
  federalSubsidizedInterestAmt: number;
  federalLoanMax: number; // for both subsidized AND unsubsidized loans
  federalUnsubsidizedPrincipalAmt: number;
  federalUnsubsidizedInterestAmt: number;
  privatePrincipalAmt: number;
  privateInterestAmt: number;
  totalLoanBalanceThisYear: number; // this is the sum of the above loan amounts
}

export interface CollegePaymentOption {
  rows: RowData[];
  monthlyPayment: number;
  loanRepaymentTerms: number;
}

export interface RowData {
  rowName: string;
  publicSubsidizedLoans: string;
  publicUnsubsidizedLoans: string;
  privateLoans: string;
}

// Results Summary types
export interface ResultsSummaryInputs {
  school: SelectedCollegeCostsInputs;
  collegeCost: FinancingOptions;
}

export interface ResultsSummaryOutputs {
  collegeCost: {
    totalCollegeCost: number;
    remainingCollegeCost: number;
  };
  avgStartingSalary: number;
  standardCollegePaymentOption: {
    rows: AmortizationRowData;
    monthlyPayment: number;
  };
  IDRPaymentOption: {
    rows: AmortizationRowData;
  };
}

export interface AmortizationRowData {
  [rowName: string]: {
    values: number[];
    symbol: string;
  };
}

export interface ResultsSummaryState {
  selectedInputs: ResultsSummaryInputs;
  showResults: boolean;
  results: ResultsSummaryOutputs;
}

export const incomeBrackets = [
  "Less than $30,000/year",
  "$30,001-48,000/year",
  "$48,001-75,000/year",
  "$75,001-110,000/year",
  "$110,001/year or more",
];

// Single Simulation types
export interface SingleSimulationState {
  selectedAnnualLoanAmount: number;
  results: SingleSimulationResults;
}

export interface SelectedSimulationInputs {
  // selectedJob: Job;
  annualLoanAmount: number;
  selectedMajor: Major;
}

export interface SingleSimulationResults {
  collegePeriod: {
    yearsInSchool: number;
    graduated: boolean;
  };
  jobPeriod: {
    yearsEmployedInJobRelatedToDegree: number;
    yearsEmployedInJobUnrelatedToDegree: number;
    yearsUnemployed: number;
  };
  summaryStats: {
    idrexpenses: number;
    idrforgivenBalance: number;
    personalExpensesOverTime: PersonalExpensesOverTime[];
    personalIDRExpensesOverTime: PersonalExpensesOverTime[];
    totalEarnings: number;
    totalExpenses: number;
    totalAssetsOverTime: { x: number; y: number }[];
    totalEarningsOverTime: { x: number; y: number }[];
    totalExpensesOverTime: { x: number; y: number }[];
    totalIDRExpensesOverTime: { x: number; y: number }[];
    milestonesOverTime: { x: number; milestone: string }[];
  };
}

// Multiple Simulation types
export interface MultipleSimulationState {
  results: MultipleSimulationResult[];
}

export interface MultipleSimulationResult {
  avgAnnualTakeHomePay: number;
  avgAnnualIncome: number;
}

export interface SimulationMetric {
  key: string;
  description?: string;
  barColor: string[];
  textColor: string;
  showDollarSign: boolean;
  showYears: boolean;
  action: string;
}

export interface SimulationMetrics {
  [metric: string]: SimulationMetric;
}

export interface FormattedHistogramDatum {
  bin: d3.Bin<number, number>;
  amount: string;
  likelihood: number;
}

export interface FormattedHistogramDataPerMetric {
  humanReadableTitle: string;
  formattedData: FormattedHistogramDatum[];
  mode: number;
  modeBins: FormattedHistogramDatum[];
}

export type Milestone =
  | "Unemployed"
  | "Employed in job related to degree"
  | "Employed in job unrelated to degree";

export interface PersonalExpensesOverTime {
  annualSalary: number;
  annualOtherExpenses: number;
  annualStudentLoanPayment: number;
  annualTaxes: number;
  annualSavings: number;
}

export interface XYPair {
  x: number;
  y: number;
}

export enum LoanPlan {
  Standard = "Standard",
  IDR = "IDR",
}

export enum GraphType {
  LineGraph = "LineGraph",
  BarGraph = "BarGraph",
  CombinedGraph = "CombinedGraph",
}
