import React from "react";
import { ProgressStepper } from "../components/Stepper";
import { abouts } from "../constants";
import { ReactNode } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import theme from "../theme";
import Footer from "../components/Footer";

interface AboutProps {
  pageNumber?: number;
  titleIcon?: string;
  title: string;
  children?: ReactNode | ReactNode[];
  decoLeft?: string;
  decoRight?: string;
}

const Style = styled.article`
  .content-blue {
    background: ${theme.colors.lightIndigo};
    padding: 3em;
  }

  .content-white {
    background: ${theme.colors.neutrals.white};
    border-radius: 0px 0px 40px 40px;
    padding: 2em;
  }

  .survey-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 65px;

    .header {
      display: flex;
      gap: 18px;
    }

    .title {
      font-size: 1.7rem;
      border-bottom: 4px solid ${theme.colors.primary};
      line-height: 150%;
    }

    .children {
      width: 70%;
    }
  }

  .input-background {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .description {
    font-size: 20px !important;
    line-height: 120%;
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .content-blue {
      background-color: ${theme.colors.neutrals.white};
      padding: 0;
    }

    .content-white {
      border-radius: 0;
      padding: 0;
    }

    .survey-content {
      .header {
        gap: 5px;
      }

      .title {
        font-size: 1.3em !important;
      }

      .deco {
        width: 30px;
      }

      .children {
        width: 100%;
      }
    }
  }
`;

export const AboutUs: React.FC<AboutProps> = ({
  pageNumber,
  title,
  titleIcon,
  children,
  decoLeft = "",
  decoRight = "",
}) => {
  return (
    <Style>
      <Nav />
      <div className="content-blue">
        {pageNumber !== undefined && (
          <ProgressStepper
            key={pageNumber}
            pageNumber={pageNumber}
            pages={abouts}
          />
        )}
        <div className="content-white">
          <section className="survey-content">
            <header className="header">
              {decoLeft && <img className="deco" src={decoLeft} alt="" />}
              {titleIcon && <img src={titleIcon} alt="" />}
              <h1 className="title">{title}</h1>
              {decoRight && <img className="deco" src={decoRight} alt="" />}
            </header>
            <article className="children">{children}</article>
          </section>
        </div>
      </div>
      <Footer />
    </Style>
  );
};

export default AboutUs;
