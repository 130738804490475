import React from "react";
import theme from "../../theme";
import { moneyFormat } from "../../simulations/utils";
import { PersonalExpensesOverTime, XYPair } from "../../types";

interface GraphTooltipProps {
  index: number;
  totalLoanExpensesOverTime: XYPair[];
  totalExpensesOverTime: XYPair[];
  totalEarningsOverTime: XYPair[];
  personalExpensesOverTime: PersonalExpensesOverTime[];
  milestonesOverTime: {
    x: number;
    milestone: string;
  }[];
}

const GraphTooltip = ({
  index,
  totalLoanExpensesOverTime,
  totalExpensesOverTime,
  totalEarningsOverTime,
  personalExpensesOverTime,
  milestonesOverTime,
}: GraphTooltipProps) => {
  let annualStudentLoanPaymentValue = totalLoanExpensesOverTime[index].y;
  let incomeValue = totalEarningsOverTime[index].y;
  // const housing = personalExpensesOverTime[index].annualHousingCosts;
  const other = personalExpensesOverTime[index].annualOtherExpenses;
  const taxes = personalExpensesOverTime[index].annualTaxes;
  const correspondingMilestoneDatum = milestonesOverTime.filter((milestone) => {
    return milestone.x === index;
  });
  const age = Number(index) + 22;

  return (
    <div className="tooltip-container">
      <b>Year {index + 1}</b>
      <b>{age} years old</b>
      <pre style={{ fontFamily: "Karla" }}>
        {correspondingMilestoneDatum[0].milestone}
      </pre>
      <div
        style={{
          borderStyle: "solid",
          borderRadius: "10px",
          borderColor: theme.colors.neutrals.gray,
          padding: "10px",
          gap: "6px",
          display: "flex",
          flexDirection: "column",
        }}
        className="expenses-container"
      >
        <div className="flex-between">
          <span className="dark-gray">Annual income: </span>
          <b
            style={{
              color: theme.colors.graphColors.turquoise,
              fontWeight: 700,
            }}
          >{`$${moneyFormat(Number(incomeValue))}`}</b>
        </div>
        <div className="flex-between">
          <span className="dark-gray">Student loan payments: </span>
          <b
            style={{
              color: theme.colors.graphColors.lightOrange,
              fontWeight: 700,
            }}
          >{`$${moneyFormat(Number(annualStudentLoanPaymentValue))}`}</b>
        </div>
        <div className="flex-between">
          <span className="dark-gray">Taxes: </span>
          <b
            style={{
              color: theme.colors.graphColors.darkPurple,
              fontWeight: 700,
            }}
          >{`$${moneyFormat(Number(taxes))}`}</b>
        </div>
        <div className="flex-between">
          <span className="dark-gray">
            <span>Other expenses: </span>
            <p className="subtext">
              (housing, food, transportation, other living expenses)
            </p>
          </span>
          <b
            style={{
              color: theme.colors.graphColors.indigo,
              fontWeight: 700,
            }}
          >{`$${moneyFormat(Number(other))}`}</b>
        </div>
      </div>
    </div>
  );
};

export default GraphTooltip;
