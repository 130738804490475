import { useSelector } from "react-redux";
import styled, { ThemeProvider } from "styled-components";
import { RootState } from "../state";
import TimelineEvent from "./TimelineEvent";
import theme from "../theme";
import DiplomaIcon from "../assets/icons/diploma-blue.svg";
import BriefcaseIcon from "../assets/icons/briefcase-blue.svg";
import { useState } from "react";
import {
  Box,
  createTheme,
  FormControl,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
} from "@mui/material";
import IDRPlanTab from "../simulations/IDRPlanSimulationTab";
import StandardPlanTab from "../simulations/StandardPlanSimulationTab";
import SimulationLineChart from "../components/graphs/SimulationLineChart";
import { GraphType, LoanPlan } from "../types";
import SimulationBarGraph from "../components/graphs/SimulationBarGraph";
import SimulationCombinedGraph from "../components/graphs/SimulationCombinedGraph";

const Style = styled.div`
  .timeline-summary-section {
    padding-bottom: 24px;
    @media (max-width: ${theme.breakpoints.xlarge}px) {
      width: 100%;
    }
  }

  .timeline-summary-heading {
    color: ${theme.colors.neutrals.darkGray};
    font-size: 18px;
    font-weight: 600;
  }

  .timeline-events {
    margin-top: 24px;

    display: flex;
    gap: 24px;
    justify-content: stretch;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
  }

  .timeline-events > * {
    flex: 0 0 22%;

    @media (max-width: ${theme.breakpoints.xlarge}px) {
      flex: 0 0 45%;
    }
  }

  .icon {
    @media (max-width: ${theme.breakpoints.xlarge}px) {
      width: 40px;
      margin: auto;
      margin-right: 20px;
    }
  }

  .timeline-event {
    height: 80%;
    @media (max-width: ${theme.breakpoints.xlarge}px) {
      padding: 20px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

      .text {
        font-size: 16px;
      }
    }
  }
  .SPTab {
    color: ${theme.colors.secondary};
    background-color: white;
  }
  .IDRTab {
    color: ${theme.colors.secondary};
    background-color: white;
  }
  .TabsStyling {
  }

  .clickable {
    cursor: pointer;
  }

  .graph {
    width: auto;
    height: 600px;
    min-width: 0;
  }

  .tooltip-container {
    background: ${theme.colors.neutrals.white};
    border-radius: 20px;
    border: 2px solid ${theme.colors.neutrals.darkGray};
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 400px;

    .tooltip-expenses {
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding: 10px 15px;

      p {
        margin: 0;
      }
    }

    @media (max-width: ${theme.breakpoints.xsmall}px) {
      width: 250px;
    }
  }

  pre {
    white-space: normal;
    margin: 5px 0;
  }

  .gray {
    color: ${theme.colors.neutrals.gray};
  }

  .dark-gray {
    color: ${theme.colors.neutrals.darkGray};
  }

  .income-color {
    font-weight: 700;
    color: ${theme.colors.graphColors.turquoise};
  }

  .expenses-color {
    font-weight: 700;
    color: ${theme.colors.graphColors.redOrange};
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .subtext {
    font-size: 12px;
    margin: 0;
  }

  @media (max-width: ${theme.breakpoints.xsmall}px) {
    .flex-between {
      flex-direction: column;
      gap: 1px;
    }

    .expenses-container {
      gap: 8px !important;
    }
  }
`;

const GraphChanger = styled.div`
  margin-bottom: 1em;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
`;

const customTheme = createTheme({
  palette: { primary: { main: theme.colors.secondary } },
});

const StylePanel = styled.div`
  .tab-panel {
    width: 100%;
    @media (max-width: ${theme.breakpoints.xsmall}px) {
      width: 90vw;
    }
  }
`;

const selectSingleSimulationState = (state: RootState) =>
  state.singleSimulation;

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <StylePanel>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className="tab-panel"
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
      </div>
    </StylePanel>
  );
};

const SingleSimulationView = () => {
  const { results } = useSelector(selectSingleSimulationState);

  const timelineEventContent = [
    // TODO: add this back in once we allow students to input major
    // {
    //   message: (
    //     <>
    //       Studied <b>{selectedInputs?.selectedMajor?.majorName || "a major"}</b>{" "}
    //       at <b>{selectedInputs.school.college?.name || "a school"}</b>
    //     </>
    //   ),
    //   icon: BookIcon,
    // },
    {
      message: (
        <>
          {results.collegePeriod.graduated
            ? "Graduated in"
            : "Dropped out after"}{" "}
          <b>{results.collegePeriod.yearsInSchool}</b> years
        </>
      ),
      icon: DiplomaIcon,
    },
    {
      message: (
        <>
          Worked at a job related to your degree for{" "}
          <b>
            {results.jobPeriod.yearsEmployedInJobRelatedToDegree
              ? results.jobPeriod.yearsEmployedInJobRelatedToDegree
              : 0}
          </b>{" "}
          years
        </>
      ),
      icon: BriefcaseIcon,
    },
    {
      message: (
        <>
          Worked at a job unrelated to your degree for{" "}
          <b>{results.jobPeriod.yearsEmployedInJobUnrelatedToDegree}</b> years
        </>
      ),
      icon: BriefcaseIcon,
    },
    {
      message: (
        <>
          Was unemployed for <b>{results.jobPeriod.yearsUnemployed}</b> years
        </>
      ),
      icon: BriefcaseIcon,
    },
  ];

  const [selectedLoanPlan, setSelectedLoanPlan] = useState(0);

  const handleLoanPlanChange = (event: React.SyntheticEvent, index: number) => {
    setSelectedLoanPlan(index);
  };

  const loanPlan = selectedLoanPlan === 0 ? LoanPlan.Standard : LoanPlan.IDR;

  const [graphType, setGraphType] = useState<GraphType>(GraphType.LineGraph);

  const handleGraphTypeChange = (event: SelectChangeEvent) => {
    setGraphType(event.target.value as GraphType);
  };

  return (
    <Style>
      <div className="timeline-summary">
        <div className="timeline-summary-section">
          <div className="timeline-summary-heading">In this universe, you:</div>
          <div className="timeline-events">
            {timelineEventContent.map((timelineEvent) => {
              return (
                <TimelineEvent
                  message={timelineEvent.message}
                  icon={timelineEvent.icon}
                />
              );
            })}
          </div>
        </div>

        <div className="timeline-summary-section">
          <div className="timeline-summary-heading">
            20 years after college:
          </div>
          <div>
            <Box
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                color: theme.colors.secondary,
              }}
            >
              <Tabs
                value={selectedLoanPlan}
                onChange={handleLoanPlanChange}
                aria-label="loan plan tabs"
                className="TabsStyling"
                variant="fullWidth"
                sx={{
                  border: 4,
                  borderRadius: 2,
                  "& button.Mui-selected": {
                    backgroundColor: theme.colors.secondary,
                    color: theme.colors.neutrals.white,
                    fontSize: 16,
                    textTransform: "none",
                    fontWeight: "bold",
                    fontFamily: theme.fonts.body,
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab
                  label="Standard Repayment Plan"
                  className="SPTab"
                  aria-selected
                  sx={{
                    color: theme.colors.secondary,
                    fontSize: 16,
                    textTransform: "none",
                    fontWeight: "bold",
                    fontFamily: theme.fonts.body,
                  }}
                />
                <Tab
                  label="Income-Driven Repayment Plan"
                  className="IDRTab"
                  aria-selected
                  sx={{
                    color: theme.colors.secondary,
                    fontSize: 16,
                    textTransform: "none",
                    fontWeight: "bold",
                    fontFamily: theme.fonts.body,
                  }}
                />
              </Tabs>
            </Box>

            <TabPanel value={selectedLoanPlan} index={0}>
              <StandardPlanTab />
            </TabPanel>

            <TabPanel value={selectedLoanPlan} index={1}>
              <IDRPlanTab />
            </TabPanel>
          </div>
        </div>

        {/* Personal expenses / income graphs  */}
        <div className="graphs">
          <ThemeProvider theme={customTheme}>
            <GraphChanger>
              <FormControl>
                <Select
                  labelId="select-filled-label"
                  id="simple-select"
                  value={graphType}
                  renderValue={() => <>View As</>}
                  onChange={handleGraphTypeChange}
                  sx={{
                    background: "#FFF5F3",
                    textAlign: "left",
                    fontSize: 16,
                    fontFamily: theme.fonts.body,

                    "& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
                      color: theme.colors.secondary,
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        borderRadius: 4,
                      },
                      sx: {
                        "& .MuiRadio-root": {
                          color: theme.colors.secondary,
                        },
                        "& .MuiMenuItem-root": {
                          fontFamily: theme.fonts.body,
                        },
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: theme.colors.neutrals.white,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={GraphType.LineGraph}>
                    <Radio checked={graphType === GraphType.LineGraph}></Radio>
                    Line Chart Only
                  </MenuItem>
                  <MenuItem value={GraphType.BarGraph}>
                    <Radio checked={graphType === GraphType.BarGraph}></Radio>
                    Bar Chart Only
                  </MenuItem>
                  <MenuItem value={GraphType.CombinedGraph}>
                    <Radio
                      checked={graphType === GraphType.CombinedGraph}
                    ></Radio>
                    Line Chart & Bar Chart
                  </MenuItem>
                </Select>
              </FormControl>
            </GraphChanger>
          </ThemeProvider>
          <TabPanel value={graphType} index={GraphType.LineGraph}>
            <SimulationLineChart results={results} loanPlan={loanPlan} />
          </TabPanel>

          <TabPanel value={graphType} index={GraphType.BarGraph}>
            <SimulationBarGraph results={results} loanPlan={loanPlan} />
          </TabPanel>

          <TabPanel value={graphType} index={GraphType.CombinedGraph}>
            <SimulationCombinedGraph results={results} loanPlan={loanPlan} />
          </TabPanel>
        </div>

        <div className="summary">
          <h4>
            Note: We are using the average unemployment rate of 5.1% from the{" "}
            <a
              href="https://www.bls.gov/charts/employment-situation/civilian-unemployment-rate.htm"
              style={{
                textDecorationLine: "underline",
                color: theme.colors.neutrals.black,
              }}
            >
              US Bureau of Labor
            </a>{" "}
            to simulate a realistic chance of being unemployed in your chosen
            career.
          </h4>
        </div>
      </div>
    </Style>
  );
};

export default SingleSimulationView;
