import styled from "styled-components";
import theme from "../../theme";
import { FC, useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { LoanPlan, SingleSimulationResults } from "../../types";
import {
  getMilestonesOverTime,
  getPersonalExpensesOverTime,
  getTotalEarningsOverTime,
  getTotalExpensesOverTime,
  getTotalLoanExpensesOverTime,
  mapPersonalExpensesToGraphData,
} from "../../simulations/utils";
import { linearGradientDef } from "@nivo/core";
import { kFormatter } from "../../utils";
import { legendColors } from "../../constants";
import GraphTooltip from "./GraphTooltip";

const Style = styled.div``;

interface StandardPlanProps {
  results: SingleSimulationResults;
  loanPlan: LoanPlan;
  layers?: any[];
  disableLegendClick?: boolean;
  showTotalExpensesLegend?: boolean;
}

const SimulationBarChart: FC<StandardPlanProps> = ({
  results,
  loanPlan,
  layers,
  disableLegendClick,
  showTotalExpensesLegend = false,
}) => {
  const mobileWidth = theme.breakpoints.xsmall;
  const windowWidth = window.innerWidth;
  const [mobile, setMobile] = useState(windowWidth < mobileWidth);

  useEffect(() => {
    setMobile(windowWidth < mobileWidth);
  }, [mobile, mobileWidth, windowWidth]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setMobile(window.innerWidth < mobileWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setMobile(window.innerWidth < mobileWidth);
      });
    };
  }, [mobileWidth]);

  const personalExpensesOverTime = getPersonalExpensesOverTime(
    results,
    loanPlan,
  );

  const totalEarningsOverTime = getTotalEarningsOverTime(results);

  const totalExpensesOverTime = getTotalExpensesOverTime(
    results,
    loanPlan,
    personalExpensesOverTime,
  );

  const totalLoanExpensesOverTime = getTotalLoanExpensesOverTime(
    results,
    loanPlan,
  );

  const milestonesOverTime = getMilestonesOverTime(results);

  const data = mapPersonalExpensesToGraphData(
    personalExpensesOverTime,
    totalEarningsOverTime,
    totalLoanExpensesOverTime,
  );

  const [legendVisibility, setLegendVisibility] = useState({
    other: false,
    studentLoans: false,
    taxes: false,
    totalIncome: false,
  });

  const colors = (
    Object.keys(legendColors) as Array<keyof typeof legendVisibility>
  )
    .filter((key) => !legendVisibility[key])
    .map((key) => legendColors[key]);

  // currently nivo bar chart "stacks" the data so the total height of the bar is the sum of all the values
  // we don't want this, we want the total height of the bar to be the total income
  const formattedData = data.map((d) => {
    return {
      ...d,
      totalIncome: d.totalIncome - (d.other + d.taxes + d.studentLoanPayments),
    };
  });

  const legendData = [
    {
      id: "totalIncome",
      label: "Annual Income",
      color: legendColors.totalIncome,
      hidden: legendVisibility.totalIncome,
    },
  ];

  const studentLoanPaymentData = {
    id: "studentLoanPayments",
    label: "Student Loan Payments",
    color: legendColors.studentLoans,
    hidden: legendVisibility.studentLoans,
  };

  const mobileLegendData = [];

  if (mobile && showTotalExpensesLegend) {
    mobileLegendData.push(studentLoanPaymentData);
  } else {
    legendData.push(studentLoanPaymentData);
  }

  // only for combined graph
  if (showTotalExpensesLegend) {
    legendData.splice(1, 0, {
      id: "totalExpenses",
      label: "Total Expenses",
      color: legendColors.totalExpenses,
      hidden: false,
    });
  }

  return (
    <Style>
      <div className="graph">
        <ResponsiveBar
          data={formattedData}
          keys={["studentLoanPayments", "taxes", "other", "totalIncome"]}
          indexBy="year"
          isInteractive={true}
          enableGridY={false}
          groupMode="stacked"
          layout="vertical"
          enableLabel={false}
          colors={colors}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["opacity", 1]],
          }}
          defs={[
            linearGradientDef("transparent", [
              { offset: 0, color: "#FFFFFF" },
              { offset: 100, color: "#FFFFFF", opacity: 100 },
            ]),
          ]}
          fill={[{ match: { id: "totalIncome" }, id: "transparent" }]}
          margin={
            window.innerWidth < mobileWidth
              ? { top: 60, right: 20, bottom: 50, left: 50 }
              : { top: 60, right: 20, bottom: 50, left: 70 }
          }
          padding={0.3}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickValues: 4,
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: (v) => `$${kFormatter(v)}`,
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 36,
            legendPosition: "middle",
            renderTick: ({ opacity, textAnchor, value, x, y }) => {
              return (
                <g transform={`translate(${x},${y})`} style={{ opacity }}>
                  <text
                    textAnchor={textAnchor}
                    transform={`translate(${0},${mobile ? 15 : 20})`}
                    alignmentBaseline={"baseline"}
                    fontSize={mobile ? "10px" : "18px"}
                  >
                    {mobile ? value : `Y${value}`}
                  </text>
                  {!mobile && (
                    <text
                      textAnchor={textAnchor}
                      transform={`translate(${0},${40})`}
                      alignmentBaseline={"baseline"}
                      fontSize="10px"
                    >
                      {21 + value} y.o.
                    </text>
                  )}
                </g>
              );
            },
            legend: mobile ? "Years after graduation" : undefined,
          }}
          theme={{
            text: {
              fontSize: 16,
              fontFamily: "Karla",
            },
            legends: { text: { fontSize: 14 } },
            axis: {
              legend: {
                text: {
                  fontSize: 14,
                },
              },
              domain: {
                line: {
                  stroke: "#777777",
                  strokeWidth: 1,
                },
              },
            },
          }}
          // Splits legends into two rows
          legends={[
            {
              data: legendData,
              dataFrom: "keys",
              anchor: "top-left",
              direction: "row",
              justify: false,
              // translateX: 180,
              translateY: -70,
              itemWidth: 150,
              itemHeight: 40,
              itemsSpacing: 2,
              symbolSize: 16,
              itemDirection: "left-to-right",
              toggleSerie: disableLegendClick ? false : true,
              onClick: (data) => {
                if (disableLegendClick) return;

                if (data.id === "totalIncome") {
                  setLegendVisibility({
                    ...legendVisibility,
                    totalIncome: !legendVisibility.totalIncome,
                  });
                } else if (data.id === "studentLoanPayments") {
                  setLegendVisibility({
                    ...legendVisibility,
                    studentLoans: !legendVisibility.studentLoans,
                  });
                }
              },
            },
            {
              data: [
                {
                  id: "taxes",
                  label: "Taxes",
                  color: legendColors.taxes,
                  hidden: legendVisibility.taxes,
                },
                {
                  id: "other",
                  label: "Other Expenses",
                  color: legendColors.other,
                  hidden: legendVisibility.other,
                },
              ],
              onClick: (data) => {
                if (disableLegendClick) return;

                if (data.id === "other") {
                  setLegendVisibility({
                    ...legendVisibility,
                    other: !legendVisibility.other,
                  });
                } else if (data.id === "taxes") {
                  setLegendVisibility({
                    ...legendVisibility,
                    taxes: !legendVisibility.taxes,
                  });
                }
              },
              dataFrom: "keys",
              anchor: "top-left",
              direction: "row",
              justify: false,
              // translateX: 180,
              translateY: -40,
              itemWidth: 150,
              itemHeight: 40,
              itemsSpacing: 2,
              symbolSize: 16,
              itemDirection: "left-to-right",
              toggleSerie: disableLegendClick ? false : true,
            },
            // Third row for mobile
            {
              data: mobileLegendData,
              onClick: (data) => {
                if (disableLegendClick) return;

                if (data.id === "studentLoanPayments") {
                  setLegendVisibility({
                    ...legendVisibility,
                    studentLoans: !legendVisibility.studentLoans,
                  });
                }
              },
              dataFrom: "keys",
              anchor: "top-left",
              direction: "row",
              justify: false,
              // translateX: 180,
              translateY: -10,
              itemWidth: 150,
              itemHeight: 40,
              itemsSpacing: 2,
              symbolSize: 16,
              itemDirection: "left-to-right",
              toggleSerie: disableLegendClick ? false : true,
            },
          ]}
          layers={layers}
          tooltip={(data) => {
            const index = data.data.year - 1;

            return (
              <GraphTooltip
                index={index}
                totalLoanExpensesOverTime={totalLoanExpensesOverTime}
                totalExpensesOverTime={totalExpensesOverTime}
                totalEarningsOverTime={totalEarningsOverTime}
                personalExpensesOverTime={personalExpensesOverTime}
                milestonesOverTime={milestonesOverTime}
              />
            );
          }}
        />
      </div>
    </Style>
  );
};

export default SimulationBarChart;
